import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import expertsData from '../../../assets/data/profile-avocats.json';
import expertsDataSecondary from '../../../assets/data/profile-avocats-secondary.json';
import servicesData from '../../../assets/data/services.json';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-expert-profile',
  templateUrl: './expert-profile.component.html',
  styleUrls: ['./expert-profile.component.css']
})
export class ExpertProfileComponent implements OnInit {
  id: string;
  expert: any;
  experts: any;
  lang: string;


  constructor(private route: ActivatedRoute, private langService: LangService) {
  	this.id = this.route.snapshot.paramMap.get('id');
  	this.lang = this.langService.getSelectedLang();
    this.experts = {...expertsData, ...expertsDataSecondary}
  	this.expert = this.experts[this.id][this.lang];
  }

  goToLinkedin(url: string): void {
    window.open(url);
  }

  isClickable(skill: string): string {
    let matchService = '';

    for (let service in servicesData) {
      console.log(skill);
      console.log(servicesData[service][this.lang].title);
      console.log(skill.toLowerCase().indexOf(servicesData[service][this.lang].title.toLowerCase()) > -1);
      console.log(servicesData[service][this.lang].title.toLowerCase().indexOf(skill.toLowerCase()) > -1);
      if (skill.toLowerCase().indexOf(servicesData[service][this.lang].title.toLowerCase()) > -1 || servicesData[service][this.lang].title.toLowerCase().indexOf(skill.toLowerCase()) > -1) {
        matchService = service.toLowerCase();
        break;
      }
    }

    return matchService;
  }

  ngOnInit() {
  }
}
