import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangService } from '../../services/lang.service';
import { ExpertService } from '../../services/expert.service';
import articlesData from '../../../assets/data/articles.json';
import expertsData from '../../../assets/data/profile-avocats.json';

@Component({
  selector: 'app-article-selected',
  templateUrl: './article-selected.component.html',
  styleUrls: ['./article-selected.component.css']
})
export class ArticleSelectedComponent implements OnInit {
  id: string;
  previous: number;
  next: number;
  idCapitalized: string;
  article: any;
  lang: string;
  numberOfArticles: number;
  currentArticleUrl: string;
  expert: any;
  expertId: any;
  associatedExperts: any;
  associatedExpertsLength: number = 0;

  constructor(private route: ActivatedRoute, private _langService: LangService, private _expertService: ExpertService) {
    this.currentArticleUrl = location.href;
  	this.numberOfArticles = Object.keys(articlesData).length;
  	this.id = this.route.snapshot.paramMap.get('id');
    this.lang = this._langService.getSelectedLang();
    this.articleHandler();
  }

  articleHandler(): void {
    if(!Object.keys(articlesData[this.id][this.lang]).length) {
      location.href = "/news";
    } else {
        this.previous = parseInt(this.route.snapshot.paramMap.get('id')) - 1;
        this.next = parseInt(this.route.snapshot.paramMap.get('id')) + 1;
        this.idCapitalized = this.id.charAt(0).toUpperCase() + this.id.slice(1);
        this.article = articlesData[this.idCapitalized][this.lang];
        this.expert = this._expertService.getExpertByName(this.article["expert"]);
        this.expertId = this.expert[0];
        this.expert = this.expert[1];
        console.log(this.expert);
        this.associatedExperts = this.setAssociatedExperts();
    }
  }

  setAssociatedExperts(): any {
    let articleCategories = this.article.categories;
    let assExperts = {};
    this.associatedExpertsLength = 0;

    for (let expert in expertsData) {
      if(this.article["expert"] != expertsData[expert][this.lang]["name"]) {
        let practiceAreas = expertsData[expert][this.lang].serviceCategories;
        let categories = this.article["categories"];
        
        for (let i = 0; i < categories.length; i++) {
          for (let j = 0; j < practiceAreas.length; j++) {
            if (practiceAreas[j].indexOf(categories[i]) > -1) {
              assExperts[expert] = expertsData[expert];
              this.associatedExpertsLength ++;
              break;
            }
          }
        }
      }
    }

    return assExperts;
  }

  toUpper(str: string): string {
  	return str.charAt(0).toUpperCase() + str.slice(1);
  }

  goToProfile(id: string): void {
    window.location.href = "/expert/" + id;
  }

  ngOnInit() {
  }

}
