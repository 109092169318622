import { Component, OnInit } from '@angular/core';
import servicesData from '../../../assets/data/services.json';
import { ActivatedRoute } from '@angular/router';
import { LangService } from '../../services/lang.service';
import { PracticeService } from '../../services/practice.service';

@Component({
  selector: 'app-area-of-practice-list',
  templateUrl: './area-of-practice-list.component.html',
  styleUrls: ['./area-of-practice-list.component.css']
})
export class AreaOfPracticeListComponent implements OnInit {
	services: any;
	lang: string;

	constructor(private route: ActivatedRoute, private _langService: LangService, private _practiceService: PracticeService) {
		this.lang = this._langService.getSelectedLang();
		this.services = this._practiceService.order();
	}

  ngOnInit() {
  }
}
