import { Injectable } from '@angular/core';
import expertsData from '../../assets/data/profile-avocats.json';
import { LangService } from '../services/lang.service';

@Injectable()
export class ExpertService {
	lang: string

	constructor(private _langService: LangService) { }

	getExpertByName(expertName: string): any {
		this.lang = this._langService.getSelectedLang();
		for (let i in expertsData) {
			for (let j in expertsData[i]) {
				if (expertsData[i][j]["name"] == expertName) {
					return [i, expertsData[i][this.lang]]
				}
			}
		}
	}
}