import { Injectable } from '@angular/core';
import servicesData from '../../assets/data/services.json';
import { LangService } from '../services/lang.service';

@Injectable()
export class PracticeService {
  services: any;
  lang: string;

  constructor(private _langService: LangService) {
    this.lang = this._langService.getSelectedLang();
  }

  order(): any {
    let arr = [];
    let orderArr = ["Company-commercial-law", "Bank-financial-law", "Stranger-law", "Lease-law", "Fiscal-law", "Criminal-law", "Contract-law", "Estate-build-law", "Labor-law", "Family-law", "Owner-online-seller-law", "Arbitration"]

    for (let service in servicesData) {
      for (let i = 0; i < orderArr.length; i++) {
        if (orderArr[i] == service) {
          servicesData[service][this.lang]['id'] = service
          arr.push(servicesData[service][this.lang]);
         }
      }
    }

    return arr;
  }
}