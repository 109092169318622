import { Injectable } from '@angular/core';

@Injectable()
export class LangService {
  getSelectedLang(): string {
    let lang:string = localStorage.getItem('selectedLang')
    if ((typeof lang === 'undefined' || lang === null)) {
      lang = 'fr';
    }
    return lang;
  }

  setSelectedLang(selectedLand): void {
    localStorage.setItem('selectedLang', selectedLand);
  }
}