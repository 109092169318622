import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import experts from '../../../assets/data/profile-avocats.json';
import articlesData from '../../../assets/data/articles.json';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-news-area',
  templateUrl: './news-area.component.html',
  styleUrls: ['./news-area.component.css']
})
export class NewsAreaComponent implements OnInit {
  @Input() id: string;
  @Input() idService: string;

  lang: string;
  articles: any = [];
  expert: any = { 'id' : '' };

  constructor(private _langService: LangService, private route: ActivatedRoute) {
  	this.lang = this._langService.getSelectedLang();
  }

  getLastThreePosts(posts): void {
    let i = 0;

  	for (let key in posts) {
      if(this.route.snapshot.paramMap.get('id') != key && i < 3 && Object.keys(articlesData[key][this.lang]).length) {
        posts[key]['number'] = key;
        this.articles.push(posts[key]);
        i++;
      }
  	}
  	this.articles = this.articles.reverse();
  }  

  getExpertArticles(posts): void {
    for (let key in posts) {
      posts[key]['number'] = key;

      if (this.expert.name == posts[key][this.lang].expert) {
        this.articles.push(posts[key]);
      }
    }
    this.articles = this.articles.reverse().slice(0, 3);
  }

  getServiceArticles(posts): void {
    for (let key in posts) {
      posts[key]['number'] = key;

      if (posts[key][this.lang]['categories'].indexOf(this.idService) > -1) {
        this.articles.push(posts[key]);
      }
    }
    this.articles = this.articles.reverse().slice(0, 3);
  }

  ngOnInit() {
    if (typeof this.id != 'undefined') {
      this.expert = experts[this.id][this.lang];
      this.getExpertArticles(articlesData);
    } else if (typeof this.idService != 'undefined') {
      this.getServiceArticles(articlesData);
    } else {
      this.getLastThreePosts(articlesData);
    }
  }
}
