import { Component, OnInit } from '@angular/core';
import { MailService } from '../../services/mail.service';

@Component({
  selector: 'app-contact-area',
  templateUrl: './contact-area.component.html',
  styleUrls: ['./contact-area.component.css']
})
export class ContactAreaComponent implements OnInit {

  constructor(public _mailService: MailService) {
  	this._mailService.mailSent = false;
  }

  ngOnInit() {
  }

}
