import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  title = 'web-avocats';

  constructor() {
  	this.toggleAppointment()
  }

  toggleAppointment() {
  	if (window.location.href.indexOf('appointment') > -1) {
  		document.getElementById('fixedAppointment').style.visibility = 'hidden';
  	} else {
  		document.getElementById('fixedAppointment').style.visibility = 'visible';
  	}
  }
}
