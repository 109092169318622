import { Component, OnInit, Input } from '@angular/core';
import { LangService } from '../../services/lang.service';
import { Expert } from '../../models/expert.model';
import { StringUtils } from '../../utils/string/string.utils';
import expertsData from '../../../assets/data/profile-avocats.json';
import expertsDataSecondary from '../../../assets/data/profile-avocats-secondary.json';


@Component({
  selector: 'app-experts',
  templateUrl: './experts.component.html',
  styleUrls: ['./experts.component.css']
})
export class ExpertsComponent implements OnInit {
  @Input() competentExperts: any; 
  experts: any;
  lang: string;
  hideContactButton: boolean;

  constructor(private _langService: LangService, private _stringUtils: StringUtils) {
    this.lang = this._langService.getSelectedLang();
    this.experts = expertsData;
  }

  goToProfile(profile: string): void {
    window.location.href = "/expert/" + profile;
  }

  goToLinkedin(url: string): void {
    window.open(url);
  }

  ngOnInit() {
    if (typeof this.competentExperts != "undefined") {
      this.experts = {}
      let experts_json = {...expertsData, ...expertsDataSecondary};
      for (let key in experts_json) {
        if (this.competentExperts.indexOf(key) > -1) {
          this.experts[key] = experts_json[key];
        }
      }

      console.log(this.experts);
    }


    if (Object.keys(this.experts).length <= 3) {
      setTimeout(() => {
        let navi: any = document.getElementsByClassName('owl-nav')[0];
        if (typeof navi != "undefined") {
          navi.style.display = 'none';
        }
      },0)
    }
  }
}
