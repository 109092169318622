import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LangService } from '../../services/lang.service';
import { MailService } from '../../services/mail.service';
import fr from '../../../assets/i18n/fr.json';
import ru from '../../../assets/i18n/ru.json';
import en from '../../../assets/i18n/en.json';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  @ViewChild("name", {read: ElementRef, static: true}) name: ElementRef
  @ViewChild("email", {read: ElementRef, static: true}) email: ElementRef
  @ViewChild("phone", {read: ElementRef, static: true}) phone: ElementRef
  @ViewChild("comment", {read: ElementRef, static: true}) comment: ElementRef
  lang: string;

  constructor(private _langService: LangService, public _mailService: MailService) {
  	this.lang = this._langService.getSelectedLang();
  	this._mailService.mailSent = false;
  }

  ngOnInit() {
  }

  goToAppointment(): void {
    let name = this.name.nativeElement.value;
    let email = this.email.nativeElement.value;
    let phone = this.phone.nativeElement.value;
    let comment = this.comment.nativeElement.value;

    if (name != "" || email != "" || phone != "" || comment != "") {
      if (confirm(this.getMessage(this.lang))) {
        document.location.href = '/appointment'
      }
    } else {
      document.location.href = '/appointment'
    }
  }

  getMessage(lang): string {
    let msg;

    switch (lang) {
      case "fr":
        msg = fr['Confirm-message']
        break;      
      case "en":
        msg = en['Confirm-message']
        break;      

      case "ru":
        msg = ru['Confirm-message']
        break;
    }
    return msg;
  }
}
