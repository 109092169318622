import { Injectable } from '@angular/core';
import { StringUtils } from '../utils/string/string.utils';
import { LangService } from './lang.service';

@Injectable()
export class CheckFormService {
	errorMsgsLength: number;
	lng: string;

	constructor(private stringUtils: StringUtils, private lang: LangService) {
		this.lng = this.lang.getSelectedLang();
	}



	checkForm(values): [boolean, any, number] {
		let bool = true;
		let keysArray = Object.keys(values);
		let errorMsgs = {};
		this.errorMsgsLength = 0;

		for (let i = 0; i < keysArray.length; i++) {
			if (keysArray[i] == "name") {
				if (this.stringUtils.removeAllWhiteSpaces(values[keysArray[i]]).length == 0) {
					bool = false;
					errorMsgs['name'] = translates[this.lng]["name"];
					this.errorMsgsLength++;
				}
			}
			if (keysArray[i] == "email") {
				if (!this.stringUtils.validateEmail(values[keysArray[i]])) {
					bool = false;
					errorMsgs['email'] = translates[this.lng]["email"];
					this.errorMsgsLength++;
				}
			}
			if (keysArray[i] == "phone") {
				if (!this.stringUtils.isDigits(values[keysArray[i]])) {
					if (values[keysArray[i]].length < 5) {
						bool = false;
						errorMsgs['phone'] = translates[this.lng]["phone"];
						this.errorMsgsLength++;
					}
				}
			}
			if (keysArray[i] == "comment") {
				if (this.stringUtils.removeAllWhiteSpaces(values[keysArray[i]]).length == 0) {
					bool = false;
					errorMsgs['comment'] = translates[this.lng]["comment"];
					this.errorMsgsLength++;
				}
			}
			if (keysArray[i] == "attorney") {
				if (this.stringUtils.removeAllWhiteSpaces(values[keysArray[i]]).length == 0) {
					bool = false;
					errorMsgs['attorney'] = translates[this.lng]["attorney"];
					this.errorMsgsLength++;
				}	
			}
			if (keysArray[i] == "date") {
				if (this.stringUtils.removeAllWhiteSpaces(values[keysArray[i]]).length == 0) {
					bool = false;
					errorMsgs['date'] = translates[this.lng]["date"];
					this.errorMsgsLength++;				
				}
			}			
			if (keysArray[i] == "server") {
				bool = false;
				errorMsgs['server'] = translates[this.lng]["server"];
				this.errorMsgsLength++;				
			}
		}

		return [bool, errorMsgs, this.errorMsgsLength];
	}
}

let translates = {
	"fr": {
		"server": "Le mail n'a pas pu être envoyé car le serveur de messagerie est indisponible, merci de nous contacter directement au mail suivant legal@dna.law",
		"name": "Le champ nom est invalide.",
		"phone": "Le champ téléphone est invalide.",
		"email": "Le champ email est invalide.",
		"attorney": "Veuillez sélectionner un expert.",
		"date": "Veuillez sélectionner une date.",
		"comment": "Le champ message est invalide."
	},

	"en": {
		"server": "Our email server is not available, please contact us directly at legal@dna.law",
		"name": "Name is not valid.",
		"phone": "Phone is not valid.",
		"email": "Email is not valid.",
		"attorney": "Please select an expert.",
		"date": "Please select a date.",
		"comment": "Your message is not valid."
	},
	"ru": {
		"server": "Our email server is not available, please contact us directly at legal@dna.law",
		"name": "Имя недействительно.",
		"phone": "Телефон не действителен.",
		"email": "Email не является допустимым.",
		"attorney": "Пожалуйста, выберите эксперта.",
		"date": "Пожалуйста, выберите дату.",
		"comment": "Ваше сообщение недействительно."
	}
}