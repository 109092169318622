import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './utils/router/app-routing.module';
import { AppComponent } from './utils/app.component';
import { IndexComponent } from './pages/index/index.component';
import { AboutComponent } from './pages/about/about.component';
import { AttorneysComponent } from './pages/attorneys/attorneys.component';
import { NewsComponent } from './pages/news/news.component';
import { ContactComponent } from './pages/contact/contact.component';
// services
import { LangService } from './services/lang.service';
import { HttpService } from './services/http.service';
import { MailService } from './services/mail.service';
import { ExpertService } from './services/expert.service';
import { PracticeService } from './services/practice.service';
import { CheckFormService } from './services/checkForm.service';
// utils
import { StringUtils } from './utils/string/string.utils';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AreaOfPracticeListComponent } from './components/area-of-practice-list/area-of-practice-list.component';
import { AreaOfPracticeComponent } from './pages/area-of-practice/area-of-practice.component';
import { ExpertsComponent } from './components/experts/experts.component';
import { ExpertsSecondaryComponent } from './components/experts-secondary/experts-secondary.component';
import { AboutAreaComponent } from './components/about-area/about-area.component';
import { ContactAreaComponent } from './components/contact-area/contact-area.component';
import { NewsletterAreaComponent } from './components/newsletter-area/newsletter-area.component';
import { FooterComponent } from './components/footer/footer.component';
import { NewsAreaComponent } from './components/news-area/news-area.component';
import { ExpertProfileComponent } from './sub-pages/expert-profile/expert-profile.component';
import { AreaOfPracticeSelectedComponent } from './sub-pages/area-of-practice-selected/area-of-practice-selected.component';
import { ArticleSelectedComponent } from './sub-pages/article-selected/article-selected.component';
import { AppointmentContactSectionComponent } from './components/appointment-contact-section/appointment-contact-section.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    AboutComponent,
    AttorneysComponent,
    NewsComponent,
    ContactComponent,
    HeaderNavComponent,
    AreaOfPracticeListComponent,
    CarouselComponent,
    AreaOfPracticeComponent,
    ExpertsComponent,
    ExpertsSecondaryComponent,
    AboutAreaComponent,
    ContactAreaComponent,
    NewsletterAreaComponent,
    FooterComponent,
    NewsAreaComponent,
    ExpertProfileComponent,
    AreaOfPracticeSelectedComponent,
    ArticleSelectedComponent,
    AppointmentContactSectionComponent,
    AppointmentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({        
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
          }
      })
  ],
  providers: [LangService, HttpService, MailService, ExpertService, PracticeService, CheckFormService, StringUtils],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
