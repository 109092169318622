export class StringUtils {
	removeAllWhiteSpaces(text: string): string {
		return text.replace(/\s/g,'');
	}

	replaceSpecialEletter(text: string): string {
		return text.replace(/ë/g,'e');
	}

	replaceSpacesAndChar(text: string): string {
		return this.removeAllWhiteSpaces(this.replaceSpecialEletter(text));
	}	

	isDigits(phone: string): boolean {
		return /^\d+$/.test(phone);
	}

	validateEmail(email): boolean {
	    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(String(email).toLowerCase());
	}
}