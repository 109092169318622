import { Component, OnInit, Input } from '@angular/core';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-appointment-contact-section',
  templateUrl: './appointment-contact-section.component.html',
  styleUrls: ['./appointment-contact-section.component.css']
})
export class AppointmentContactSectionComponent implements OnInit {
  @Input() expert: any;
  @Input() expertId: string;
  hideContactButton: boolean;
  lang: string;

  constructor(private _langService: LangService) {
    this.lang = this._langService.getSelectedLang();
  	this.hideContactButton = window.location.href.indexOf('attorneys') > -1 || window.location.href.indexOf('area-of-practice') > -1;
  }

  ngOnInit() {
  }

}
