import { Component, OnInit } from '@angular/core';
import pickadate from 'pickadate';
import * as translationFR from 'pickadate/lib/translations/fr_FR';
import * as translationRU from 'pickadate/lib/translations/ru_RU';
import expertsData from '../../../assets/data/profile-avocats.json';
import expertsDataSecondary from '../../../assets/data/profile-avocats-secondary.json';
import { LangService } from '../../services/lang.service';
import { ActivatedRoute } from '@angular/router';
import { MailService } from '../../services/mail.service';


@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {
  lang: string;
  experts: any;
  expertId: string = '';
  picker: any;
  date: string;
  defaultTime = false;
  dateValue: string;
  attorney: string;

  constructor(private _langService: LangService, private route: ActivatedRoute, public _mailService: MailService) {
  	this.lang = this._langService.getSelectedLang();
  	this.experts = {...expertsData, ...expertsDataSecondary};
  	this.expertId = this.route.snapshot.paramMap.get('id');
    this._mailService.mailSent = false;
    if (this.expertId !=  null) {
      this.attorney = this.experts[this.expertId][this.lang].name;
    }
  }

  ngOnInit() {
  	this.initDatepicker();
    this.renderTimeLabel();
    this.bindOnChangeToPickadate();
  }

  bindOnChangeToPickadate(): void {
    const element = document.getElementById('pickadate');
    const onChange = formattedValue => {
      this.rdv();
    }
    element.addEventListener('pickadate:change', onChange);
  }

  initDatepicker(): void {
  	//let yesterday = new Date((new Date()).valueOf()-1000*60*60*24);
  	//let today = new Date((new Date()).valueOf());
  	let tomorrow = new Date((new Date()).valueOf()+1000*60*60*24);

  	let translation;

  	this.picker = pickadate.create();

  	if (this.lang == 'fr') {
  		translation = translationFR.default;
  	}  	

  	if (this.lang == 'ru') {
  	    translation = translationRU.default;
  	}

  	if (this.lang != 'en') {
  		this.picker.setFirstDayOfWeek({ value: translation.firstDayOfWeek });
  		this.picker.setTemplateHookWords(translation.templateHookWords);
  	}

  	this.picker.setMinimum({ value: tomorrow });
  	this.picker.setDisabled({ value: [0, 6] });
    this.picker.setTemplate({ value: "D MMMM, YYYY @ HH:mm" });

	const element = document.getElementById('pickadate');
	pickadate.render(element, this.picker, { mode: "date-time", className: {time: 'pickadate--time'}});
  }

  renderTimeLabel() {
    let hoursContainer: any = document.getElementsByClassName('pickadate--element pickadate--time_unit pickadate--time_unit__hours')[0];
    hoursContainer.insertAdjacentHTML('afterbegin', '<input class="pick--time-label" id="hoursLabel" readonly="true" />');

    let hoursLabelHTML: any = document.getElementById('hoursLabel');
    hoursLabelHTML = document.getElementById('hoursLabel');
    hoursLabelHTML.value = "09";
  }

  rdv() {
    let hoursLabelHTML: any = document.getElementById('hoursLabel');

    if (!this.defaultTime) {
      this.defaultTime = true;
      this.picker.setTime({ hours: 9 });
      this.date = this.picker.getValue();
    } else {
      let time: any = this.picker.getValue().split(' ')[4];
      let hour: number = parseInt((time.split(':')[0]));

      if (hour == 23) {
        this.picker.setTime({ hours: 11 });
      } else if (hour == 0) {
        this.picker.cycleMeridiem();
        this.picker.setTime({ hours: 12 });
      } else if (hour >= 18) {
        this.picker.cycleMeridiem();
        this.picker.setTime({ hours: 18, minutes: 0});
      } else if (hour < 9) {
        this.picker.setTime({ hours: 9 });
      }
      this.date = this.picker.getValue();

      time = this.picker.getValue().split(' ')[4];
      hour = time.split(':')[0];

      hoursLabelHTML = document.getElementById('hoursLabel');
      hoursLabelHTML.value = hour;
    }
  }
}


/*
    this.picker.setMinimum({ value: today });
    console.log(today);
    this.picker.setDisabled({ value: [0, 6] });
    console.log(this.picker);
    //this.picker.setTime({ hours: 9 });
    const element = document.getElementById('pickadate');
    pickadate.render(element, this.picker);*/