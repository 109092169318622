import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PracticeService } from '../../services/practice.service';
import { LangService } from '../../services/lang.service';
import servicesData from '../../../assets/data/services.json';

@Component({
  selector: 'app-area-of-practice-selected',
  templateUrl: './area-of-practice-selected.component.html',
  styleUrls: ['./area-of-practice-selected.component.css']
})
export class AreaOfPracticeSelectedComponent implements OnInit {
  id: string;
  idCapitalized: string;
  service: any;
  services: any;
  lang: string;

  constructor(private route: ActivatedRoute, private _langService: LangService, private _practiceService: PracticeService) {
  	this.id = this.route.snapshot.paramMap.get('id');
  	this.idCapitalized = this.id.charAt(0).toUpperCase() + this.id.slice(1);
  	this.lang = this._langService.getSelectedLang();
  	this.service = servicesData[this.idCapitalized][this.lang];
    this.services = this._practiceService.order();
  }

  ngOnInit() {
  }

}

