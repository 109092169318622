import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from '../../pages/index/index.component';
import { AboutComponent } from '../../pages/about/about.component';
import { AttorneysComponent } from '../../pages/attorneys/attorneys.component';
import { AreaOfPracticeComponent } from '../../pages/area-of-practice/area-of-practice.component';
import { ArticleSelectedComponent } from '../../sub-pages/article-selected/article-selected.component';
import { AreaOfPracticeSelectedComponent } from '../../sub-pages/area-of-practice-selected/area-of-practice-selected.component';
import { AppointmentComponent } from '../../pages/appointment/appointment.component';
import { NewsComponent } from '../../pages/news/news.component';
import { ContactComponent } from '../../pages/contact/contact.component';
import { ExpertProfileComponent } from '../../sub-pages/expert-profile/expert-profile.component';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


const routes: Routes = [
  //{ path: '', component: IndexComponent, pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: IndexComponent },
  { path: 'about', component: AboutComponent },
  { path: 'area-of-practice', component: AreaOfPracticeComponent },
  { path: 'attorneys', component: AttorneysComponent },
  { path: 'news', component: NewsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'expert/:id', component: ExpertProfileComponent },
  { path: 'area-of-practice/:id', component: AreaOfPracticeSelectedComponent },
  { path: 'news/:id', component: ArticleSelectedComponent },
  { path: 'appointment', component: AppointmentComponent },
  { path: 'appointment/:id', component: AppointmentComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}