import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';
import { Router } from '@angular/router';
import { StringUtils } from '../../utils/string/string.utils';
import expertsData from '../../../assets/data/profile-avocats.json';
import expertsDataScondary from '../../../assets/data/profile-avocats-secondary.json';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.css']
})
export class HeaderNavComponent implements OnInit {
	lang: string;
	activeRouteLink: string;
  experts: any;

	constructor(
				  private translate: TranslateService, 
				  private langService: LangService,
				  private router: Router,
          private _stringUtils: StringUtils
				) {
	  this.initLang();
	  this.onUrlChange();
    this.experts = {...expertsData, ...expertsDataScondary};
  }

  /* Triggered everythime url changes */
  onUrlChange(): void {
  	this.router.events.subscribe((val) => {
  		if (typeof(val['url']) !== 'undefined') {
  			this.activeRouteLink = val['url'];
  		}
  	});
  }

  goToIndex(): void {
    window.location.href = "/home";
  }

  initLang(): void {
  	this.lang = this.langService.getSelectedLang();
    this.translate.use(this.lang);
  }

  saveLang(lang): void {
  	this.langService.setSelectedLang(lang);
  	this.translate.use(lang);
    // reload page
    window.location.reload();
  }

  ngOnInit() {
  }

}
