import { Component, OnInit } from '@angular/core';
import articlesData from '../../../assets/data/articles.json';
import { LangService } from '../../services/lang.service';
import { PracticeService } from '../../services/practice.service';
import servicesData from '../../../assets/data/services.json';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  articles: any;
  articlesDisplayed: any;
  lang: string;
  first: number;
  last: number;
  services: any;
  numberOfPages: number;
  currentPage: number;
  pagesIndex: number[] = [];

  constructor(private _langService: LangService, private _practiceService: PracticeService) {
    this.services = this._practiceService.order();
  	this.articles = articlesData;
  	this.currentPage = 1;
    this.lang = this._langService.getSelectedLang();
  	this.articlesDisplayed = this.getArticleFromPage(this.currentPage);
  	this.numberOfPages = Object.keys(this.articles).length % 3 ? Math.floor(Object.keys(this.articles).length / 3) + 1 : Math.floor(Object.keys(this.articles).length / 3);
  	this.setPagesIndex();
  }

  toUpper(str: string): string {
  	return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getArticlesLength(): number {
    let len = 0;

    for (let id in this.articles) {
      if(this.articles[id].hasOwnProperty(this.lang)) {
        if(Object.keys(this.articles[id][this.lang]).length > 0) {
          len++;
        } else {
          delete this.articles[id];
        }
      }
    }
    
    return len;
  }

  getArticleFromPage(page: number): any {
  	let numberOfArticles = this.getArticlesLength();
    //let numberOfArticles = Object.keys(this.articles).length;
  	let articlePerPages = 3;
  	let currentRange = ((page - 1) * 3);

  	this.first = (numberOfArticles - currentRange) > -1 ? (numberOfArticles - currentRange) : 0;
  	this.last = (numberOfArticles - currentRange - articlePerPages) > -1 ? (numberOfArticles - currentRange - articlePerPages) : 0;

  	let sliced = Object.keys(this.articles).slice(this.last, this.first).reduce((result, key) => {
  	                    result[key] = this.articles[key];

  	                    return result;
  	                }, {});

  	return this.toArray(sliced);
  }

  filterArticles(filter: string): void {
    let filteredArticles = {};

    this.articles = articlesData;

    if (filter != 'all') {
      for (let article in this.articles) {
        let categories = this.articles[article][this.lang].categories;
        for (let i = 0; i < categories.length; i++) {
          if (categories[i] == filter) {
            filteredArticles[article] = this.articles[article];
            break;
          }
        }
      }
      this.articles = filteredArticles;
    }

    this.currentPage = 1;
    this.articlesDisplayed = this.getArticleFromPage(this.currentPage);
    this.numberOfPages = Object.keys(this.articles).length % 3 ? Math.floor(Object.keys(this.articles).length / 3) + 1 : Math.floor(Object.keys(this.articles).length / 3);
    this.setPagesIndex();
  }

  toArray(obj: any): any {
  	let tmp = [];

  	for (let key in obj) {
  		obj[key]['number'] = key;
  		tmp.push(obj[key]);
  	}

  	return tmp.reverse();
  }

  previous(): void {
  	this.currentPage == 1 ? this.currentPage = 1 : this.currentPage -= 1;
  	this.articlesDisplayed = this.getArticleFromPage(this.currentPage);
  	document.getElementById("pdopage").scrollIntoView();
  }  

  next(): void {
  	this.currentPage == this.numberOfPages ? this.currentPage = this.numberOfPages : this.currentPage += 1;
  	this.articlesDisplayed = this.getArticleFromPage(this.currentPage);
  	document.getElementById("pdopage").scrollIntoView();
  }

  setPagesIndex(): void {
    this.pagesIndex = [];
  	for (let i = 0; i < this.numberOfPages; ++i) {
  		this.pagesIndex.push(i + 1);
  	}
  }

  refreshPage(pageIndex: number): void {
  	this.currentPage = pageIndex;
  	this.articlesDisplayed = this.getArticleFromPage(pageIndex);
  	document.getElementById("pdopage").scrollIntoView();
  }

  maxchar(text): string {
    let limit = 280;
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  }

  ngOnInit() {
  }
}
