import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CheckFormService } from './checkForm.service';
import { LangService } from './lang.service';

@Injectable()
export class MailService {
  mailSent: boolean = false;
  errorMsgs: any = { };
  errorMsgsLength: number = 0;

  constructor(private http: HttpClient, private _checkFormService: CheckFormService, private lang: LangService) {

  }

  sendMail(ngForm: any): void { 
    let translates = {
      "en": {
        "new-message-subject": "New contact form message - website - dna.law",
        "new-appointment-subject": "New message to take an appointment - website dna.law",
        "confirm-appointment-subject": "Confirmation of your appointment request - dna.law",
        "confirm-message-subject": "Confirmation of receipt of your message - dna.law",
        "appointment-message": "Dear Sir, Dear Madam,<br><br>We have received your request for an appointment.<br>If you would like to change your appointment request, please email us with your availability at the following email address: <A HREF=mailto:legal@dna.law'>legal@dna.law</A>.<br><br>In case of emergency, do not hesitate to contact us by phone at the number +41 (0) 22 736 20 42.<br><br>Kind regards,<br>dna.law",
        "message": "Dear Sir, Dear Madam,<br><br>We have received your message.<br>If you would like to make an appointment, please email us with your availability at the following email address: <A HREF=mailto:legal@dna.law'>legal@dna.law</A>.<br>In case of emergency, do not hesitate to contact us by phone at the number +41 (0) 22 736 20 42.<br><br>Kind regards,<br>dna.law"
      },
      "fr": {
        "new-message-subject": "Nouveau message du formulaire de contact - website dna.law",
        "new-appointment-subject": "Nouveau message pour prendre un RDV - website dna.law",
        "confirm-appointment-subject": "Confirmation de votre demande de prise de RDV - dna.law",
        "confirm-message-subject": "Confirmation de réception de votre message - dna.law",
        "appointment-message": "Madame, Monsieur,<br><br>Nous avons bien reçu votre demande de rendez-vous.<br>Si vous souhaitez modifier votre demande de rendez-vous, merci de nous envoyer un courriel avec vos disponibilités à l’adresse email suivante : <A HREF=mailto:legal@dna.law'>legal@dna.law</A>.<br><br>En cas d’urgence, n’hésitez pas à nous contacter par téléphone au numéro +41 (0) 22 736 20 42.<br><br>Bien à vous,<br>dna.law",
        "message": "Madame, Monsieur,<br><br>Nous avons bien reçu votre message.<br>Si vous souhaitez prendre rendez-vous, merci de nous envoyer un courriel avec vos disponibilités à l’adresse email suivante : <A HREF=mailto:legal@dna.law'>legal@dna.law</A>.<br><br>Bien à vous,<br>dna.law"
      },
      "ru": {
        "new-message-subject": "Новое сообщение контактной формы - сайт- dna.law",
        "new-appointment-subject": "Новое сообщение о записи на прием - сайт dna.law",
        "confirm-appointment-subject": "Подтверждение заявки на прием - dna.law",
        "confirm-message-subject": "Подтверждение получения Вашего сообщения - dna.law",
        "appointment-message": "Уважаемый клиент,<br><br>Мы получили Ваш запрос на прием.<br>Если Вы желаете изменить Ваш запрос, пожалуйста, напишите нам по следующему адресу электронной почты: <A HREF=mailto:legal@dna.law'>legal@dna.law</A><br><br>В случае возникновения чрезвычайной ситуации, обращайтесь к нам напрямую по указанному номеру +41 (0) 22 736 20 42.<br><br>С уважением,<br>dna.law",
        "message": "Уважаемый клиент,<br><br>Мы получили Ваше сообщение.<br>Если Вы хотите записаться на прием, пожалуйста, напишите нам с указанием удобного Вам временного интервала по адресу электронной почты: <A HREF=mailto:legal@dna.law'>legal@dna.law</A>.<br>В случае возникновения чрезвычайной ситуации, обращайтесь к нам напрямую по указанному номеру +41 (0) 22 736 20 42.<br><br>С уважением,<br>dna.law"
      }
    }

    let msg: any = ngForm.form.value;
    msg["lang"] = this.lang.getSelectedLang();
    msg["dnzweb"] = "true";
    let checkMail = this._checkFormService.checkForm(msg);
    this.errorMsgsLength = 0;

    if (checkMail[0]) {
      let name = 'Nom: ' + msg.name;
      let mail = 'Email: ' + msg.email;
      let phone = 'Téléphone: ' + msg.phone;
      let attorney = '';
      let date = '';
      let text = msg.comment;
      let subject;
      let dna_mail = "legal@dna.law";

      if(msg.email == "jonathan.graille@gmail.com") {
        dna_mail = "jonathan.graille@gmail.com";
      }

      if (typeof msg.attorney != "undefined") {
        attorney = 'Expert: ' + msg.attorney;
        subject = translates[msg["lang"]]["new-appointment-subject"];
      } else {
        subject = translates[msg["lang"]]["new-message-subject"];
      }

      if (typeof msg.date != "undefined") {
        date = 'Date: ' + msg.date;
      }

      msg["dna_mail"] = dna_mail;
      msg["subject"] = subject;
      msg["attorney"] = attorney;
      msg["date"] = date;
      msg["comment"] = msg.comment + '<br><br>' + '---' + '<br>' + name + '<br>' + mail + '<br>' + phone + '<br>' + attorney  + '<br>' + date;

      //Send mail


      this.http.get<any>('https://app-jo.atanar.net/cors/app-jo.atanar.net/nodemail/mail?text=' + JSON.stringify(msg)).subscribe({
        next: data => {
          this.mailSent = true; 
          document.location.href = document.location.href.replace(/#form-title/g, '') + "#form-title";

          //confirm
          let content = "";
          if(typeof msg.attorney != "undefined") {
            subject = translates[msg["lang"]]["confirm-appointment-subject"];
            content = translates[msg["lang"]]["appointment-message"];
          } else {
            subject = translates[msg["lang"]]["confirm-message-subject"];
            content = translates[msg["lang"]]["message"];
          }

          msg["subject"] = subject;
          msg["comment"] = content;
          msg["confirm"] = "true";

          this.http.get<any>('https://app-jo.atanar.net/cors/app-jo.atanar.net/nodemail/mail?text=' + JSON.stringify(msg)).subscribe({
            error: error => {
              setTimeout(()=>{
                this.http.get<any>('https://api.telegram.org/bot1814394035:AAHJffgm23a3AP8dnCmlbE6Zr4BtXlMUVvQ/sendMessage?chat_id=1266430704&text=' + JSON.stringify(msg)).subscribe({ next: data => {}});
              },0);
            }
          })
        },
        error: error => {
          setTimeout(()=>{
            checkMail = this._checkFormService.checkForm({server:"down"});
            this.errorMsgs = checkMail[1];
            this.errorMsgsLength = checkMail[2];
            this.http.get<any>('https://api.telegram.org/bot1814394035:AAHJffgm23a3AP8dnCmlbE6Zr4BtXlMUVvQ/sendMessage?chat_id=1266430704&text=' + JSON.stringify(msg)).subscribe({ next: data => {}});
          },0);
        }
      });
    } else {
      this.errorMsgs = checkMail[1];
      this.errorMsgsLength = checkMail[2];
    }
  }
}

export interface mail {
  attorney: string;
  date: string;
  email: string;
  name: string;
  phone: string;
  question: string;
}